import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserService } from '@core/services';
import { InfoMessagesService } from '@features/info-messages';
import { AccountService } from '@features/server-side-authentication';
import { finalize, map, tap } from 'rxjs';
import { MimicUserDataService } from '../../services/mimic-user-data/mimic-user-data.service';

@Component({
  selector: 'app-user-mimic-status-bar',
  templateUrl: './user-mimic-status-bar.component.html',
  styleUrl: './user-mimic-status-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMimicStatusBarComponent {
  public isLoading = signal<boolean>(false);
  public isUserMimic = signal<boolean>(false);

  public userName = toSignal<string>(
    this.userService.getUserData().pipe(
      map((el): string => el?.name || '<user name>'),
      tap(() => this.isUserMimic.set(this.accountService.isUserMimic)),
    ),
  );

  constructor(
    private readonly userService: UserService,
    private readonly accountService: AccountService,
    private readonly infoMessagesService: InfoMessagesService,
    private readonly mimicUserDataService: MimicUserDataService,
    private readonly router: Router,
  ) {}

  onClick() {
    this.isLoading.set(true);
    this.mimicUserDataService
      .mimicStop()
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: (res) => {
          this.accountService.accessToken = res.access;
          this.accountService.refreshToken = res.refresh;
          this.router.navigate(['/reload'], { skipLocationChange: true }).then(() => {
            this.router.navigate(['/']);
          });
        },
        error: (err) => {
          this.infoMessagesService.showError(err.error.error);
        },
      });
  }
}
