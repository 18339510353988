import { HttpErrorResponse } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { InfoMessagesService } from '@features/info-messages';
import { AccountService } from '@features/server-side-authentication';
import { MimicUserDataService } from '../../services/mimic-user-data/mimic-user-data.service';

@Directive({
  selector: '[turnOnMimic]',
})
export class TurnOnMimicDirective {
  @Input('turnOnMimic') userId!: number;

  @HostListener('click', ['$event']) onClick() {
    if (this.accountService.isUserMimic) {
      this.infoMessagesService.showWarning('The user mimic function is not available for you!');
    } else {
      this.mimicUserDataService.mimicStart(this.userId).subscribe({
        next: (res) => {
          this.accountService.accessToken = res.access;
          this.accountService.refreshToken = res.refresh;
          this.router.navigate(['/reload'], { skipLocationChange: true }).then(() => {
            this.router.navigate(['/']);
          });
        },
        error: (err: HttpErrorResponse) => {
          this.infoMessagesService.showError(err.error.error);
        },
      });
    }
  }

  constructor(
    private readonly mimicUserDataService: MimicUserDataService,
    private readonly accountService: AccountService,
    private readonly infoMessagesService: InfoMessagesService,
    private readonly router: Router,
  ) {}
}
