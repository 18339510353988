import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UserMimicStatusBarComponent } from './components/user-mimic-status-bar/user-mimic-status-bar.component';
import { TurnOnMimicDirective } from './directives/turn-on-mimic/turn-on-mimic.directive';
import { MimicUserDataService } from './services/mimic-user-data/mimic-user-data.service';

@NgModule({
  declarations: [TurnOnMimicDirective, UserMimicStatusBarComponent],
  exports: [TurnOnMimicDirective, UserMimicStatusBarComponent],
  imports: [CommonModule, MatButtonModule],
})
export class MimicUserModule {
  static forRoot(): ModuleWithProviders<MimicUserModule> {
    return {
      ngModule: MimicUserModule,
      providers: [MimicUserDataService],
    };
  }
}
