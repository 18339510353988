import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { Observable } from 'rxjs';
import { StartMimicUserDTO, StopMimicUserDTO } from '../../models';

@Injectable()
export class MimicUserDataService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
  ) {}

  mimicStart(user_id: number): Observable<StartMimicUserDTO> {
    return this.http.post<StartMimicUserDTO>(`${this.apyBaseUrl}/api/mimic/start/${user_id}/`, {});
  }

  mimicStop(): Observable<StopMimicUserDTO> {
    return this.http.get<StopMimicUserDTO>(`${this.apyBaseUrl}/api/mimic/stop/`);
  }
}
